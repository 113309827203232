<template>
  <div class="settingcuration_container">
    <alert-modal
      v-if="alertModal"
      :alertText="`시간대별 큐레이션 설정은 최대 5개까지 가능합니다`"
      @closeModal="onCloseAlertModal"
    />
    <timecuration-modal
      v-if="isTimeCurationModal"
      :curationinfo="timeCurationInfo"
      @click-close="isTimeCurationModal = false"
      @move-modifycuration="onMoveModifycuration"
    />
    <div class="curationtime_container_wrap">
      <ul class="flex_space_between align_center settingcuration_title_wrap">
        <li>
          <h2 class="settingcuration_title">시간대별 큐레이션 설정</h2>
        </li>
        <li class="cursor" @click="onClickPre">
          <img src="/media/img/close_btn.png" alt="닫기" />
        </li>
      </ul>
      <mocuration-header @clickpre="onClickPreMo">
        <template #mocurationtitle>
          시간대별 큐레이션 설정3
        </template>
      </mocuration-header>

      <ul class="flex_space_between curationtime_tab align_center">
        <li class="dp_flex">
          <button
            class="curationtime_tab_btn"
            :class="{ active: activeComp === 'curation-timelist' }"
            @click="onClickActiveTab('curation-timelist')"
          >
            <img class="btn_icon_list" src="/media/img/curation/list.png" alt="리스트" />
            리스트
          </button>
          <button
            class="curationtime_tab_btn calendar"
            :class="{ active: activeComp === 'curation-calendar' }"
            @click="onClickActiveTab('curation-calendar')"
          >
            <img class="btn_icon_list" src="/media/img/curation/calendar.png" alt="캘린더" />
            캘린더
          </button>
        </li>
        <li>
          <button class="curationtime_add_btn" @click="onClickAddTimeCuration">
            <img class="btn_icon_list" src="/media/img/curation/addbtn.png" alt="시간대 설정 추가" />
            시간대 설정 추가
          </button>
        </li>
      </ul>

      <ul class="active_tab">
        <li>
          <component
            :is="activeComp"
            v-bind="{ ...getprops }"
            @click-curationitem="onClickCurationitem"
            @opencuration-detail="onOpenCurationDetail"
            @opencuration-default="onOpencurationDefault"
          ></component>
        </li>
      </ul>
    </div>

    <div class="curationtime_add_btn_mobile" @click="onClickAddTimeCuration">
      <img src="/media/img/curation/plus_white.png" class="curationtime_add_image" alt="시간별 큐레이션 추가" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { selectCurationInfoAll } from '@/service/api/profileApi';
export default defineComponent({
  name: 'SettingTimeView',
  components: {
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`),
    'curation-timelist': () => import(`@/components/curation/CurationTimeList.vue`),
    'curation-calendar': () => import(`@/components/curation/CalendarComp.vue`),
    'mocuration-header': () => import(`@/components/curation/MoCurationHeader.vue`),
    'timecuration-modal': () => import(`@/components/curation/TimeCurationModal.vue`)
  },
  data() {
    return {
      activeComp: 'curation-timelist',
      spaceId: '',
      curationTimeList: [],
      isTimeCurationModal: false,
      timeCurationInfo: null,
      alertModal: false
    };
  },
  created() {
    if (Object.keys(this.$route.params).length > 0) {
      const { spaceid } = this.$route.params;
      this.spaceId = spaceid;

      this.setTimeCurationList();
    }
  },

  mounted() {},

  methods: {
    onCloseAlertModal() {
      this.alertModal = false;
    },
    onMoveModifycuration(val) {
      if (val) {
        const { spaceId, curationId } = val;
        this.$router.push({ path: `/curationsetting/${spaceId}/${curationId}` });
      }
    },
    onOpencurationDefault(val) {
      if (val) {
        this.timeCurationInfo = val;
        this.isTimeCurationModal = true;
      }
    },
    onOpenCurationDetail(val) {
      if (val) {
        const title = `${val.startAmPm} ${val.startTimeTitle} ~ ${val.endAmPm} ${val.endtTimeTitle}`;
        val.title = title;
        this.timeCurationInfo = val;
        this.isTimeCurationModal = true;
      }
    },
    onClickCurationitem(item) {
      const { spaceId, curationId, startTimeDate, endTImeDate } = item;
      let curationType = 'default';
      if (startTimeDate && endTImeDate) {
        curationType = 'time';
      }
      this.$router
        .push({ path: `/curationsetting/${spaceId}/${curationId}?curationtype=${curationType}` })
        .catch(() => {});
    },
    onClickAddTimeCuration() {
      if (this.curationTimeList.length >= 6) {
        this.alertModal = true;
        return true;
      }
      this.$router.push({ path: `/curationsetting/${this.spaceId}?type=new` }).catch(() => {});
    },
    onClickPre() {
      if (Object.keys(this.$route.query).length > 0) {
        const { fromUrl } = this.$route.query;
        this.$router.push({ name: fromUrl, params: { openCuration: true } }).catch(() => {});
      } else {
        this.$router.push({ name: 'MySpace' }).catch(() => {});
      }
    },
    onClickPreMo() {
      if (Object.keys(this.$route.query).length > 0) {
        const { fromUrl } = this.$route.query;
        this.$router.push({ name: fromUrl, params: { openCuration: false, osType: 'mobile' } }).catch(() => {});
      } else {
        this.$router.push({ name: 'MySpace' }).catch(() => {});
      }
    },
    onClickActiveTab(val) {
      this.activeComp = val;
    },
    getColorCss(index) {
      const csscolorlist = ['second', 'third', 'four', 'five', 'six'];
      if (index === 0) return 'first';
      const mod = index % 5;
      return csscolorlist[mod];
    },
    async setTimeCurationList() {
      try {
        const { data } = await selectCurationInfoAll(this.spaceId);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          const list = result || [];
          const len = list.length;
          for (let i = 0; i < len; i++) {
            list[i].genre = list[i].genre ? list[i].genre : [];
            list[i].period = list[i].period ? list[i].period : [];
            const target =
              list[i].target.length > 1 ? `${list[i].target[0]}+${list[i].target.length - 1}` : list[i].target[0];
            const targetUnitList =
              list[i].targetUnit.length > 1
                ? `${list[i].targetUnit[0]}+${list[i].targetUnit.length - 1}`
                : list[i].targetUnit[0];
            const domesticRate = `국내${list[i].domesticRate}%`;
            const genre = list[i].genre;
            const period =
              list[i].period.length > 1
                ? `${list[i].period[0]}s+${list[i].period.length - 1}`
                : `${list[i].period[0]}s`;
            const emotion =
              list[i].emotion.length > 1 ? `${list[i].emotion[0]}+${list[i].emotion.length - 1}` : list[i].emotion[0];
            let startAmPm = null;
            let startTimeTitle = null;
            let endtTimeTitle = null;
            let endAmPm = '';
            // 요일
            let day = null;
            if (list[i].dayOfWeek) {
              const daylist = list[i].dayOfWeek
                .split(',')
                .map(item => parseInt(item))
                .sort((a, b) => a - b)
                .map(item => {
                  const week = ['월', '화', '수', '목', '금', '토', '일'];
                  return week[item - 1];
                });
              day = daylist.join(', ');
            }

            // 큐레이션 제목
            let title = null;
            if (list[i].startTime && list[i].endTime) {
              const startTime = list[i].startTime;
              const endTIme = list[i].endTime;
              const startSplit = startTime.split(':');
              const endSplit = endTIme.split(':');
              let startHour = startSplit[0];
              const startMin = startSplit[1];
              let endHour = endSplit[0];
              const endMin = endSplit[1];

              if (parseInt(startHour) >= 12) {
                startAmPm = '오후';
              } else {
                startAmPm = '오전';
              }
              if (parseInt(startHour) - 12 > 0) {
                startHour = parseInt(startHour) - 12;
              }

              startTimeTitle = `${startHour}:${startMin}`;

              if (parseInt(endHour) >= 12) {
                endAmPm = '오후';
              } else {
                endAmPm = '오전';
              }
              if (parseInt(endHour) - 12 > 0) {
                endHour = parseInt(endHour) - 12 > 9 ? parseInt(endHour) - 12 : `0${parseInt(endHour) - 12}`;
              }

              endtTimeTitle = `${endHour}:${endMin}`;
            } else {
              title = '기본(설정시간대 외 큐레이션)';
            }

            let genreTotal = [];
            if (genre) {
              genreTotal = [...genre];
            }

            if (list[i].genreTuneN) {
              genreTotal = [...genreTotal, ...list[i].genreTuneN];
            }

            if (list[i].genreTuneY) {
              genreTotal = [...genreTotal, ...list[i].genreTuneY];
            }

            const curationTimeItem = {
              curationId: list[i].curationId,
              spaceId: list[i].spaceId,
              target,
              targetUnitList,
              domesticRate,
              genre: genreTotal.length > 1 ? `${genreTotal[0]}+${genreTotal.length - 1}` : genreTotal[0],
              period,
              emotion,
              day,
              title,
              startAmPm,
              endAmPm,
              startTimeTitle,
              endtTimeTitle,
              startTimeDate: list[i].startTime,
              endTImeDate: list[i].endTime,
              colorcss: this.getColorCss(i)
            };

            this.curationTimeList.push(curationTimeItem);
          }
        } else {
          this.curationTimeList = [];
        }
      } catch (error) {
        console.error('setTimeCurationList error : ', error);
        this.curationTimeList = [];
      } finally {
        // 나머지 큐레이션은 현재 활성화된 순서
        const creatorCurationId = this.$store.getters['player/getCreatorCurationId'];

        const list = this.curationTimeList.sort(a => {
          if (!a.startTimeDate && !a.endTImeDate) {
            return -1;
          } else {
            return a.curationId === creatorCurationId ? -1 : 0;
          }
        });
        list.forEach((item, index) => {
          item.colorcss = this.getColorCss(index);
        });
        this.curationTimeList = list;
      }
    }
  },
  computed: {
    getprops() {
      const compName = this.activeComp;
      if (compName === 'curation-timelist') {
        return {
          curationtimelist: this.curationTimeList
        };
      } else if (compName === 'curation-calendar') {
        return {
          curationtimelist: this.curationTimeList
        };
      }
      return {};
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/settingtimeview.css"></style>
